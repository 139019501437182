<template>
  <FormKit
    type="form"
    id="salon-services"
    :actions="false"
    v-model="initValue"
    :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
    @submit="submit"
  >
    <FormKit
      name="service_name"
      type="text"
      label="Nama Layanan"
      validation="required"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
    />
    <FormKit
      name="service_desc"
      type="text"
      label="Deskripsi Layanan"
      validation="required"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
    />
    <FormKit
      name="salon_id"
      type="select"
      label="Salon"
      validation="required"
      placeholder="Pilih Salon"
      :classes="defaultFormikListClass"
      :options="salons"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown
            v-model="context.value"
            @change="
              (e) => {
                context.node.input(e.value);
              }
            "
            :options="context.options"
            optionLabel="label"
            optionValue="value"
            :placeholder="context.placeholder"
            :disabled="isAdminSalon()"
          >
            <template #option="optionContext">
              <option
                v-if="!optionContext.option.attrs?.hidden"
                :key="optionContext.index"
                :value="optionContext.option.value"
              >
                {{ optionContext.option.label }}
              </option>
              <option
                v-else
                :key="optionContext.index"
                :disabled="true"
                :value="optionContext.option.value"
              >
                Not Selected
              </option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>

    <FormKit
      name="category_id"
      type="select"
      label="Kategori"
      validation="required"
      placeholder="Pilih Kategori"
      :classes="defaultFormikListClass"
      :options="categories"
    >
      <template #input="context">
        <div class="p-fluid">
          <Dropdown
            v-model="context.value"
            @change="
              (e) => {
                context.node.input(e.value);
              }
            "
            :options="context.options"
            optionLabel="label"
            optionValue="value"
            :placeholder="context.placeholder"
          >
            <template #option="optionContext">
              <option
                v-if="!optionContext.option.attrs"
                :key="optionContext.index"
                :value="optionContext.option.value"
              >
                {{ optionContext.option.label }}
              </option>
              <option v-else :key="optionContext.index" :disabled="true">
                Not Selected
              </option>
            </template>
          </Dropdown>
        </div>
      </template>
    </FormKit>

    <FormKit
      name="estimated_time"
      type="text"
      label="Estimasi Waktu"
      help="Dalam satuan menit"
      validation="required|number"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
    />

    <!-- <FormKit
      name="admin_fee"
      type="text"
      label="Biaya Admin"
      validation="required|number"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
    /> -->

    <div class="formkit-outer tw-my-2 tw-w-full" v-if="initValue && initValue.status">
      <div class="formkit-wrapper">
        <label class="formkit-label tw-text-base" for="input_17">Status Layanan</label>
        <div class="formkit-inner tw-py-2 tw-overflow-hidden">
          <InputSwitch v-model="initValue.isActive" />
        </div>
      </div>
    </div>

    <FormKit
      :type="uploadImage"
      name="image"
      label="Foto"
      validation="required"
      validation-visibility="dirty"
      :classes="defaultFormikListClass"
      :src="initValue 
        ? initValue.image 
          ? (initValue.image.objectURL 
            ? initValue.image.objectURL 
            : initValue.image) 
          : null
        : null"
      :upload="submitUpload"
    />
  </FormKit>
  
  <div class="p-fluid tw-mt-12 tw-mb-4">
    <Button label="Submit" @click="submitData" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref, onBeforeMount } from "vue";
import { createInput, submitForm } from "@formkit/vue";

import FormUploadImage from "@/components/form/FormUploadImage";
import { defaultFormikListClass } from "@/constants/formik";
import { useSalonDropdown } from "@/composables/salon";
import { useCategoryDropdown } from "@/composables/category";
import { useAuthRole } from "@/composables/auth";

const { isAdminSalon, salonId } = useAuthRole();
const { salons } = useSalonDropdown();
const { categories } = useCategoryDropdown();
const uploadImage = createInput(FormUploadImage, {
  props: ["src", "upload"],
});

const emit = defineEmits(["submit", "upload"]);
const props = defineProps({
  initialData: {
    type: Object,
    required: false,
  },
});
const initValue = ref(props.initialData);

const submit = (data) => {
  const formData = {
    ...data,
    admin_fee: 0,
    category_id: parseInt(data.category_id),
    estimated_time: parseInt(data.estimated_time),
    salon_id: parseInt(data.salon_id),
    is_home_service: parseInt(data.is_home_service),
    status: data.isActive ? 1 : 0,
  };
  emit("submit", formData);
};

const imagePreview = ref('');

const submitUpload = async (data) => {
  if (!data) return

  imagePreview.value = data.objectURL;
  await emit("upload", data);
};

onBeforeMount(() => {
  if (isAdminSalon()) {
    initValue.value = {
      salon_id: String(salonId.value),
    };
  }
});

watch(
  () => props.initialData,
  (newData) => {
    initValue.value = newData;
    initValue.value.service_name = newData.name
  }
);

const submitData = () => {
  submitForm("salon-services");
};
</script>
